// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div class=\"imgsoldier\"><img onError='this.src = \"/sites/all/themes/custom/assets/images/WOI-2.png\"' src=\"http://files.warveterans.be/pics/"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.IdPhoto : stack1), depth0))
    + ".JPG\" alt=\"photo soldier\"></div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"imgsoldier\"><img src=\"/sites/all/themes/custom/assets/images/WOI-2.png\" alt=\"photo soldier\"></div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div id=\"person-id-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.idPersonne : stack1), depth0))
    + "\" class=\"col-md-4\" style=\"height: 415px;\">\r\n  <div class=\"col-md-12 img\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.IdPhoto : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\r\n  <div class=\"col-md-12 cnt\">\r\n    <div class=\"inner-cnt\">\r\n      <h2>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.familienaam : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.voornaamNl : stack1), depth0))
    + "</h2>\r\n      <div class=\"\"><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Conflict : stack1), depth0))
    + " : </span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.conflict : stack1), depth0))
    + "</div>\r\n      <div class=\"\"><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.DiedOn : stack1), depth0))
    + " : </span><span class=\"date-display-single\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.diedOn : stack1), depth0))
    + "</span></div>\r\n      <div class=\"readmore\"><a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.personUrl : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.ReadMore : stack1), depth0))
    + "</a></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});
