(function($) {
  $(document).ready(function() {
    // Init settings
    JsonApiSettings.init();
    if (Drupal.settings.current_path == json_api_settings.dead_person_landing_page_url) {
      // Define template
      var template = require("./templates/dead_person.hbs");
      var person_id = Number(getUrlParameter('idPersonne'));
      var url = 'https://api.whi.be/wdr.php/person';
      // Erase main container.
      $("#block-system-main").empty();
      // Append loading spinner.
      $("#block-system-main").append('<div style="text-align:center;"><img src="/sites/all/themes/custom/assets/images/ajax-loader.gif" alt="Loading..." /></div>');
      // If peson id is not provided try to get it from cookie
      if (isNaN(person_id)) {
        person_id = Number(jsonAPIsearchNode_getCookie("jsonAPIsearchNode_idPersonne"));
      }
      if (!isNaN(person_id) && typeof person_id != 'undefined' && person_id != 0) {
        $.getJSON(url + '/key=' + person_id).done(function (data) {
          if (typeof data[0] !== 'undefined') {
            // Erase main container.
            $("#block-system-main").empty();
            // If at least surname is given then generate the content.
            if (typeof data[0].familienaam !== 'undefined' && data[0].familienaam != '') {
              // Set person id in cooki in order to not loose it after language switch
              jsonAPIsearchNode_setCookie('jsonAPIsearchNode_idPersonne', person_id, 360);
              var language = Drupal.settings.languages;
              var lang = jsonAPIsearchNode_language_mapping(language);

              // Pepare values for template
              switch (language) {
                case 'en':
                  data[0].rank_url = '/en/military-abbreviations#grade';
                  data[0].unit_url = '/en/military-abbreviations#unit';
                  break;
                case 'fr':
                  data[0].rank_url = '/fr/abreviations-militaires#grade';
                  data[0].unit_url = '/fr/abreviations-militaires#unit';
                  break;
                case 'nl':
                  data[0].rank_url = '/nl/militaire-afkortingen#grade';
                  data[0].unit_url = '/nl/militaire-afkortingen#unit';
                  break;
                case 'de':
                  data[0].rank_url = '/de/militarabkurzungen#grade';
                  data[0].unit_url = '/de/militarabkurzungen#unit';
                  break;
              }
              data[0].operation = data[0]["Operation" + lang.toUpperCase()];
              if (typeof data[0].numFiche !== 'undefined' && data[0].numFiche != '' && typeof data[0].repertoire !== 'undefined' && data[0].repertoire != '') {
                data[0].pdfUrl = 'http://files.warveterans.be/pdf/' + data[0].repertoire + '/' + data[0].numFiche + '.PDF';
              }

              // Handle hero - preprocess variable and add DISQUS
              if (!isNaN(data[0].gerepatrieerd) && data[0].gerepatrieerd != 0) {
                data[0].gerepatrieerd = Drupal.t('Yes');
                /**
                 *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
                 *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables*/
                /*
                var disqus_config = function () {
                this.page.url = PAGE_URL;  // Replace PAGE_URL with your page's canonical URL variable
                this.page.identifier = PAGE_IDENTIFIER; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
                };
                */
                (function() { // DON'T EDIT BELOW THIS LINE
                  var d = document, s = d.createElement('script');
                  s.src = 'https://wardeadregister.disqus.com/embed.js';
                  s.setAttribute('data-timestamp', +new Date());
                  (d.head || d.body).appendChild(s);
                })();
                // Show banner.
                if ($('#block-views-banners-b-1list-nav').length && !$('#block-views-banners-b-1list-nav').hasClass('show-it')) {
                  $('#block-views-banners-b-1list-nav').addClass('show-it');
                }
              }
              else {
                data[0].gerepatrieerd = Drupal.t('No');
                // Hide Banner.
                if ($('#block-views-banners-b-1list-nav').length && $('#block-views-banners-b-1list-nav').hasClass('show-it')) {
                  $('#block-views-banners-b-1list-nav').removeClass('show-it');
                }
              }

              // Prepare labels for template
              var labels = {
                Hero: Drupal.t("Repatriated"),
                whoami: Drupal.t('Who am I'),
                CardDepartmentMilitaryCemeteries: Drupal.t('Card Department Military Cemeteries'),
                Nationality: Drupal.t('Nationality'),
                MyLifeBeforeTheWar: Drupal.t('My life before the war'),
                DateOfBirth: Drupal.t('Date of birth'),
                PlaceOfBirth: Drupal.t('Place of birth'),
                Address: Drupal.t('Address'),
                NameFather: Drupal.t('Name father'),
                NameMother: Drupal.t('Name mother'),
                MarriedWith: Drupal.t('Married with'),
                Profession: Drupal.t('Profession'),
                BodyHeight: Drupal.t('Body height'),
                HairColour: Drupal.t('Hair colour'),
                MyMilitaryCareer: Drupal.t('My military career'),
                DateOfDeath: Drupal.t('Date of death'),
                CauseOfDeath: Drupal.t('Cause of death'),
                PlaatsHospitalisatie: Drupal.t('Plaats hospitalisatie'),
                PlaceOfDeath: Drupal.t('Place of death'),
                PlaceOfBurial: Drupal.t('Place of burial'),
                Cemetery: Drupal.t('Cemetery'),
                PlaceOfBurial: Drupal.t('Place of burial'),
                DateOfEnlistment: Drupal.t('Date of enlistment'),
                Rank: Drupal.t('Rank'),
                ServiceNumber: Drupal.t('Service number'),
                Unit: Drupal.t('Unit'),
                Operations: Drupal.t('Operations')
              };
              data[0].labels = labels;
              var content = template(data[0]);
            }
            // Else show message that person not found.
            else {
              var content = template({conflitNl: 'Dode niet gevonden',conflitFr: 'Mort introuvable',conflitAl: 'Tote nicht gefunden',conflitGb: 'Dead not found'});
            }
            // Use the template
            //var content = template(data[0]);
            $("#block-system-main").append(content);
          }
          // If response failed then show message that service temporairly unavailable.
        }).fail(function () {
          // Erase main container.
          $("#block-system-main").empty();
          var content = template({conflitNl: 'Service momenteel niet beschikbaar. Probeer het later nogmaals.',conflitFr: 'Le service n\'est actuellement pas disponible. Veuillez réessayer plus tard.',conflitAl: 'Service derzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal.',conflitGb: 'Service currently unavailable. Please try again later.'});
          $("#block-system-main").append(content);
        });
      }
      // If no person Id provided then show message that person not found.
      else {
        // Erase main container.
        $("#block-system-main").empty();
        var content = template({conflitNl: 'Dode niet gevonden',conflitFr: 'Mort introuvable',conflitAl: 'Tote nicht gefunden',conflitGb: 'Dead not found'});
        content += '</div>';
        $("#block-system-main").append(content);
      }
    }
  });
  // Language mapping function from drupal to api shortcut
  function jsonAPIsearchNode_language_mapping(language){
    switch(language) {
      case 'nl':
        return 'Nl';
      case 'fr':
        return 'Fr';
      case 'de':
        return 'Al';
      case 'en':
        return 'Gb';
      default:
        return 'Gb';
    }
  }
  // Get url oaraneter
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }
  };
  // Helper function to set cookie
  function jsonAPIsearchNode_setCookie (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
  }
  // Helper function to get cookie
  function jsonAPIsearchNode_getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
})(jQuery);