(function($) {
  $(document).ready(function() {
    // Init settings
    JsonApiSettings.init();
    var people_container = $('#block-views-mmw-wardead-block-1 .view .view-content .row');
    if (people_container.length) {
      // Define template
      var template = require("./templates/random_people_row.hbs");
      // Run only when searcher was not performed.
      if (typeof getUrlParameter('surname') === 'undefined') {
        // Erase main container.
        people_container.empty();
        // Append loading spinner.
        people_container.append('<div style="text-align:center;"><img src="/sites/all/themes/custom/assets/images/ajax-loader.gif" alt="Loading..." /></div>');
        // Get conflicts API
        $.getJSON('https://api.whi.be/wdr.php/conflict').done(function (data) {
          if (data.length) {
            var url = 'https://api.whi.be/wdr.php/person/photo';
            var language = Drupal.settings.languages;
            var lang = jsonAPisearchFrontRandomPeople_language_mapping(language);
            var conflicts = [];

            $.each(data, function (key, value) {
              conflicts.push(value.conflitNl);
            });

            // Get random conflict
            var conflict = conflicts[Math.floor(Math.random() * conflicts.length)];
            url = url + '&conflict=' + conflict;
            // Get number of results
            $.getJSON(url + '&count').done(function (data) {
              var results = 0;
              if (typeof data[0]['nbrOfResults'] !== 'undefined') {
                results = Number(data[0]['nbrOfResults']);
              }

              if (results > 0) {
                var offset = 0;
                // Generate random number offset between 0 and results minus 6
                if (results > 6) {
                  var offset = Math.floor(Math.random() * ((results - 6) + 1));
                }

                var content = '';
                // Get random people
                $.getJSON(url + '&offset=' + offset + '&limit=6').done(function (data) {
                  if (data.length) {
                    var row = {};
                    row.labels = {
                      Conflict: Drupal.t('Conflict'),
                      DiedOn: Drupal.t('Died on'),
                      ReadMore: Drupal.t('Read more')
                    };
                    row.language = language;
                    for (var i = 0; i < data.length; i++) {
                      // Prepare some values
                      data[i].conflict = (typeof data[i]['conflit' + lang] !== 'undefined' && data[i]['conflit' + lang] != '' ? data[i]['conflit' + lang] : '');
                      data[i].diedOn = (typeof data[i].overledenVermistOpDateAAfficher !== 'undefined' && data[i].overledenVermistOpDateAAfficher != '' ? data[i].overledenVermistOpDateAAfficher : '');
                      data[i].personUrl = '/'+ language +'/' + json_api_settings.dead_person_landing_page_url + '?idPersonne='+ data[i].idPersonne;
                      row.person = data[i];
                      // Generate html
                      content += template(row);
                    }
                  }
                  else {
                    $('#block-views-mmw-wardead-block-1').hide();
                  }
                  // Erase main container.
                  people_container.empty();
                  // Inject dead people content.
                  people_container.append(content);
                }).fail(function () {
                  // Erase main container.
                  people_container.empty();
                  people_container.append('');
                  $('#block-views-mmw-wardead-block-1').hide();
                });
              }

            }).fail(function () {
              // Erase main container.
              people_container.empty();
              people_container.append('');
              $('#block-views-mmw-wardead-block-1').hide();
            });

          }
        }).fail(function () {
          // Erase main container.
          people_container.empty();
          people_container.append('');
          $('#block-views-mmw-wardead-block-1').hide();
        });
      }
    }
    
    // Language mapping function from drupal to api shortcut
  function jsonAPisearchFrontRandomPeople_language_mapping(language){
    switch(language) {
      case 'nl':
        return 'Nl';
      case 'fr':
        return 'Fr';
      case 'de':
        return 'Al';
      case 'en':
        return 'Gb';
      default:
        return 'Gb';
    }
  }
    
  });
  
  // Get url oaraneter
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
  };
  
})(jQuery);
