// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <div class=\"view-content\">\r\n            <a id=\"searchresult\"></a>\r\n            <table class=\"views-table cols-6 table table-0 table-0 table-0 table-0\">\r\n              <thead>\r\n                <tr>\r\n                  <th class=\"views-field views-field-field-conflict\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Conflict : stack1), depth0))
    + "</th>\r\n                  <th class=\"views-field views-field-field-familienaam\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Surname : stack1), depth0))
    + "</th>\r\n                  <th class=\"views-field views-field-field-voornaamnl\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.FirstName : stack1), depth0))
    + "</th>\r\n                  <th class=\"views-field views-field-field-overledenvermistopdateaaff\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.DateOfDeath : stack1), depth0))
    + "</th>\r\n                  <th class=\"views-field views-field-field-overledenvermistte-\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.DeathPlace : stack1), depth0))
    + "</th>\r\n                  <th class=\"views-field views-field-nothing\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Cemetery : stack1), depth0))
    + "</th>\r\n                </tr>\r\n              </thead>\r\n              <tbody>\r\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.data : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              </tbody>\r\n            </table>\r\n          </div>\r\n          "
    + ((stack1 = ((helper = (helper = helpers.pager || (depth0 != null ? depth0.pager : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"pager","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\r\n          <div class=\"view-footer\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.ResultsNumber : stack1), depth0)) != null ? stack1 : "")
    + "</div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                  <tr class=\""
    + alias4(((helper = (helper = helpers.classes || (depth0 != null ? depth0.classes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data}) : helper)))
    + "\">\r\n                    <td class=\"views-field views-field-field-conflict\">"
    + alias4(((helper = (helper = helpers.conflict || (depth0 != null ? depth0.conflict : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conflict","hash":{},"data":data}) : helper)))
    + "</td>\r\n                    <td class=\"views-field views-field-field-familienaam\">\r\n                      <a href=\""
    + alias4(((helper = (helper = helpers.personURL || (depth0 != null ? depth0.personURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"personURL","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.familienaam || (depth0 != null ? depth0.familienaam : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"familienaam","hash":{},"data":data}) : helper)))
    + "</a>\r\n                    </td>\r\n                    <td class=\"views-field views-field-field-voornaamnl\">"
    + alias4(((helper = (helper = helpers.voornaamNl || (depth0 != null ? depth0.voornaamNl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"voornaamNl","hash":{},"data":data}) : helper)))
    + "</td>\r\n                    <td class=\"views-field views-field-field-overledenvermistopdateaaff\">"
    + alias4(((helper = (helper = helpers.overledenVermistOpDateAAfficher || (depth0 != null ? depth0.overledenVermistOpDateAAfficher : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"overledenVermistOpDateAAfficher","hash":{},"data":data}) : helper)))
    + "</td>\r\n                    <td class=\"views-field views-field-field-overledenvermistte-\">"
    + alias4(((helper = (helper = helpers.overledenVermistTe2 || (depth0 != null ? depth0.overledenVermistTe2 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"overledenVermistTe2","hash":{},"data":data}) : helper)))
    + "</td>\r\n                    <td class=\"views-field views-field-nothing\">"
    + alias4(((helper = (helper = helpers.reinhumation || (depth0 != null ? depth0.reinhumation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reinhumation","hash":{},"data":data}) : helper)))
    + "</td>\r\n                  </tr>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <div class=\"view-empty\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.NoResultsFound : stack1), depth0))
    + "</div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<section class=\"col-sm-12\">\r\n  <a id=\"main-content\"></a>\r\n  <h1 class=\"page-header\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.SearchResults : stack1), depth0))
    + "</h1>\r\n  <div class=\"region region-content\">\r\n    <section id=\"block-system-main\" class=\"block block-system clearfix\">\r\n      <div class=\"view view-mmw-wardead view-id-mmw_wardead view-display-id-page table-responsive view-dom-id-b805411612e258596c1e392c026e81de jquery-once-3-processed\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\r\n    </section>\r\n  </div>\r\n</section>\r\n          ";
},"useData":true});
