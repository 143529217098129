(function($) {
  $(document).ready(function() {
    /*Override searcher with JSON API*/
    var dead_search_form = $('#views-exposed-form-mmw-wardead-page');
    if (dead_search_form.length) {
      var language = Drupal.settings.languages;
      var lang = theme_language_mapping(language);
      var language_field = (language == 'en' ? 'gb' : language == 'de' ? 'al_' : language);
      var per_page = 10;
      var url = 'https://api.whi.be/wdr.php/person';

      // Init settings
      JsonApiSettings.init();
      json_api_settings.parameters_list['cemetery'].selector = json_api_settings.parameters_list['cemetery'].selector.replace('[language_field]', language_field);

      // Handle Pager
      Drupal.behaviors.jsonAPIsearcher = {
        attach: function (context, settings) {
          // Make pager fully clientside
          $('.pager-pos-json-api-search').click( function (event) {
            // Prevent default bahavior
            event.preventDefault();
            // Update url
            var current_page = Number($(this).attr('href'));
            var current_url = window.location.href; 
            var newUrl = current_url; 
            newUrl = theme_updateQueryStringParameter(newUrl, 'page', current_page);
            window.history.pushState("object or string", "Title", newUrl);
            
            var results = Number($('#pager-json-api-search').attr('results'));
            
            // Update/reload pager
            $('#pager-json-api-search').replaceWith(json_api_search_pager(per_page, current_page, results));
            Drupal.attachBehaviors('#main-content');
        
            // Update/reload searcher table
            var qs = jsonAPIsearch_getQueryString();
            // Get some values from url:
            $.each( qs, function( key, value ) { 
              value = value.replace(/\\"/g, '"');
              if (json_api_settings.parameters_list[key]) {
                switch (json_api_settings.parameters_list[key]['type']) {
                  case 'dropdown':
                    json_api_settings.parameters_list[key].value = value;
                  break;
                  case 'textbox':
                    json_api_settings.parameters_list[key].value = theme_escapeSpecialCharachters('surname', value);
                  break;
                  case 'date':
                    json_api_settings.parameters_list[key].value = (!isNaN(Date.parse(value)) ? new Date(value + ' 3:30 pm').toISOString().substring(0, 10) : '');
                  break;
                  case 'dropdown_bool':
                    json_api_settings.parameters_list[key].value = (!isNaN(value) ? value : 0);
                  break;
                }
              }
            });
            // Update url query
            var queried_url = url + '/';
            for(var propertyName in json_api_settings.parameters_list) {
              queried_url += json_api_settings.parameters_list[propertyName]['name']+'='+json_api_settings.parameters_list[propertyName]['value']+'&';
            }
            queried_url = queried_url.slice(0,-1);
            // Define parameters to pass and update url - nothing to update
            var params = {};
            // Show loader
            $('body').prepend('<div id="cover-loader" style="background: url(\'/sites/all/themes/custom/assets/images/ajax-loader.gif\') no-repeat scroll center center #FFF; position: fixed; z-index: 999; opacity:.9; height: 100%; width: 100%;"></div>');
            $.when(theme_jsonAPIsearcher (queried_url, true, lang, language, params, per_page, current_page, results)).done(function() {
              // Scroll to element
              $('html, body').animate({
                scrollTop: $("#main-content").offset().top
              }, 500);
            });
          });
        }
      };
    
      // Do we want to autosubmit the form
      var autosubmit = false;
      // Get querystring from url
      var qs = jsonAPIsearch_getQueryString ();
      
      /*Populate Confilict dropdown list with values from JSON API*/
      var $select = $('#edit-field-conflict-tid');
      $select.find('option').remove();
      $select.trigger("chosen:updated");
      $.getJSON('https://api.whi.be/wdr.php/conflict').done(function (data) {
        $('<option>').val('').text(Drupal.t("- All -")).appendTo($select);
        $.each(data, function (key, value) {
          // Check if conflict contains battles and if so - split them
          if (typeof value['conflit' + lang]['battles' + lang] !== 'undefined') {
            // Check for conflict
            if (typeof value['conflit' + lang][0] !== 'undefined') {
              var option_value = value['conflit' + lang][0];
              if (qs['conflict'] && qs['conflict'] == value.conflitNl[0]) {
                $('<option selected>').val(value.conflitNl[0]).text(option_value).appendTo($select);
                autosubmit = true;
              }
              else {
                $('<option>').val(value.conflitNl[0]).text(option_value).appendTo($select);
              }
              // Check for battles
              $.each(value['conflit' + lang]['battles' + lang], function (bkey, bvalue) {
                if (qs['conflict'] && qs['conflict'] == bvalue) {
                  $('<option selected>').val(bvalue).text(' -- ' + bvalue).appendTo($select);
                  autosubmit = true;
                }
                else {
                  $('<option>').val(bvalue).text(' -- ' + bvalue).appendTo($select);
                }
              });
            }
          }
          // else regular flat structure conflict
          else {
            var option_value = value['conflit' + lang];
            if (qs['conflict'] && qs['conflict'] == value.conflitNl) {
              $('<option selected>').val(value.conflitNl).text(option_value).appendTo($select);
              autosubmit = true;
            }
            else {
              $('<option>').val(value.conflitNl).text(option_value).appendTo($select);
            }
          }
        });
        $select.trigger("chosen:updated");
      });

      
      // If at least one parameter concern search form, then auto-provide it into form and submit
      $.each( qs, function( key, value ) { 
        value = value.replace(/\\/g, "");
        if (json_api_settings.parameters_list[key]) {
          switch (json_api_settings.parameters_list[key]['type']) {
            case 'textbox':
              dead_search_form.find( "input[name='"+json_api_settings.parameters_list[key]['selector']+"']" ).val(value);
              autosubmit = true;
            break;
            case 'date':
              dead_search_form.find( "input[name='"+json_api_settings.parameters_list[key]['selector']+"']" ).val(value);
              autosubmit = true;
            break;
            case 'dropdown_bool':
              var select_bool = dead_search_form.find( json_api_settings.parameters_list[key]['selector'] + ' option' );
              select_bool.removeAttr('selected')
                .filter('[value='+value+']')
                .attr('selected', true);
              select_bool.trigger("chosen:updated");
              autosubmit = true;
            break;
          }
        }
      });
      
      // Autosubmit the form
      if (autosubmit) {
        var current_page = 0;
        // Get some values from url:
        $.each( qs, function( key, value ) {
          if (json_api_settings.parameters_list[key]) {
            switch (json_api_settings.parameters_list[key]['type']) {
              case 'dropdown':
                json_api_settings.parameters_list[key].value = value;
              break;
              case 'textbox':
                json_api_settings.parameters_list[key].value = theme_escapeSpecialCharachters('surname', value);
              break;
              case 'date':
                json_api_settings.parameters_list[key].value = (!isNaN(Date.parse(value)) ? new Date(value + ' 3:30 pm').toISOString().substring(0, 10) : '');
              break;
              case 'dropdown_bool':
                json_api_settings.parameters_list[key].value = (!isNaN(value) ? value : 0);
              break;
            }
          }
          switch (key) {
            case 'page':
              current_page = Number(value);
            break;
          }
        });
        // Update url query
        var queried_url = url + '/';
        for(var propertyName in json_api_settings.parameters_list) {
          queried_url += json_api_settings.parameters_list[propertyName]['name']+'='+json_api_settings.parameters_list[propertyName]['value']+'&';
        }
        queried_url = queried_url.slice(0,-1);
        // Define parameters to pass and update url - nothing to update
        var params = {};
        // Show loader
        $('body').prepend('<div id="cover-loader" style="background: url(\'/sites/all/themes/custom/assets/images/ajax-loader.gif\') no-repeat scroll center center #FFF; position: fixed; z-index: 999; opacity:.9; height: 100%; width: 100%;"></div>');
        
        // Get number of results
        $.getJSON( queried_url + '&count').done(function( data ) {
          var results = 0;
          if (typeof data[0]['nbrOfResults'] !== 'undefined') {
            results = Number(data[0]['nbrOfResults']);
          }
          // Update table
          $.when(theme_jsonAPIsearcher (queried_url, true, lang, language, params, per_page, current_page, results)).done(function() {
            // Scroll to element
            $('html, body').animate({
              scrollTop: $("#main-content").offset().top
            }, 500);
          });
        }).fail(function() {
          if ($('body').hasClass('front')) {
            $('body').removeClass('front').addClass('not-front').addClass('page-wardead-register');
            // Erase main container.
            $( ".main-container > .row" ).empty();
            // Move search form.
            $(".header-container-wrapper .header-container .content_top").detach().prependTo('.main-container > .row');
            // Hide content bottom.
            $('.content_bottom-wrapper').hide();
          } else {
             $( ".main-container > .row > section" ).remove();
          }
          var content = '<section class="col-sm-12"><a id="main-content"></a><h1 class="page-header">' + Drupal.t("Search results") + '</h1><div class="region region-content"><section id="block-system-main" class="block block-system clearfix"><div class="view view-mmw-wardead view-id-mmw_wardead view-display-id-page table-responsive view-dom-id-b805411612e258596c1e392c026e81de jquery-once-3-processed">';
           content += '<div class="view-empty">' +Drupal.t("Service currently unavailable. Please try again later.") + '</div>';
           content += '</div></section></div></section>';
          $( ".main-container > .row" ).append( content );
          // Remove loader
          $('#cover-loader').fadeOut();
        });
        
      } 
      
      // Attach a submit handler to the form (on click submit form)
      dead_search_form.submit(function( event ) {
        // Stop form from submitting normally
        event.preventDefault();
        // Get some values from input elements on the page:
        var params = {};
        // Get some values from input elements on the page:
        for(var propertyName in json_api_settings.parameters_list) {
          switch (json_api_settings.parameters_list[propertyName]['type']) {
            case 'dropdown':
              json_api_settings.parameters_list[propertyName].value = $(json_api_settings.parameters_list[propertyName]['selector']).find(":selected").val();
              params[propertyName] = json_api_settings.parameters_list[propertyName].value;
              break;
            case 'textbox':
              json_api_settings.parameters_list[propertyName].value = dead_search_form.find( "input[name='"+json_api_settings.parameters_list[propertyName]['selector']+"']" ).val();
              params[propertyName] = json_api_settings.parameters_list[propertyName].value;
              break;
            case 'date':
              json_api_settings.parameters_list[propertyName].value = dead_search_form.find( "input[name='"+json_api_settings.parameters_list[propertyName]['selector']+"']" ).val();
              params[propertyName] = json_api_settings.parameters_list[propertyName].value;
              break;
            case 'dropdown_bool':
              json_api_settings.parameters_list[propertyName].value = (['0','1'].indexOf($(json_api_settings.parameters_list[propertyName]['selector']).find(":selected").val()) != -1 ? $(json_api_settings.parameters_list[propertyName]['selector']).find(":selected").val() : '');
              params[propertyName] = json_api_settings.parameters_list[propertyName].value;
              break;
          }
        }
        params['page'] = 0;

        // Escape parameters
        for(var propertyName in json_api_settings.parameters_list) {
          switch (json_api_settings.parameters_list[propertyName]['type']) {
            case 'textbox':
              json_api_settings.parameters_list[propertyName].value = theme_escapeSpecialCharachters(propertyName, json_api_settings.parameters_list[propertyName].value);
              break;
            case 'date':
              json_api_settings.parameters_list[propertyName].value = (!isNaN(Date.parse(json_api_settings.parameters_list[propertyName].value)) ? new Date(json_api_settings.parameters_list[propertyName].value + ' 3:30 pm').toISOString().substring(0, 10) : '');
              break;
          }
        }

        // Update url query
        var queried_url = url + '/';
        for(var propertyName in json_api_settings.parameters_list) {
          queried_url += json_api_settings.parameters_list[propertyName]['name']+'='+json_api_settings.parameters_list[propertyName]['value']+'&';
        }
        queried_url = queried_url.slice(0,-1);

        // Show loader
        $('body').prepend('<div id="cover-loader" style="background: url(\'/sites/all/themes/custom/assets/images/ajax-loader.gif\') no-repeat scroll center center #FFF; position: fixed; z-index: 999; opacity:.9; height: 100%; width: 100%;"></div>');
        
        // Get number of results
        $.getJSON( queried_url + '&count').done(function( data ) {
          var results = 0;
          if (typeof data[0] != 'undefined' && typeof data[0]['nbrOfResults'] !== 'undefined') {
            results = data[0]['nbrOfResults'];
          }
          // Update table
          $.when(theme_jsonAPIsearcher (queried_url, false, lang, language, params, per_page, 0, results)).done(function() {
             // Scroll to element
            $('html, body').animate({
              scrollTop: $("#main-content").offset().top
            }, 500);
          });
        }).fail(function() {
          if ($('body').hasClass('front')) {
            $('body').removeClass('front').addClass('not-front').addClass('page-wardead-register');
            // Erase main container.
            $( ".main-container > .row" ).empty();
            // Move search form.
            $(".header-container-wrapper .header-container .content_top").detach().prependTo('.main-container > .row');
            // Hide content bottom.
            $('.content_bottom-wrapper').hide();
          } else {
             $( ".main-container > .row > section" ).remove();
          }
          var content = '<section class="col-sm-12"><a id="main-content"></a><h1 class="page-header">' + Drupal.t("Search results") + '</h1><div class="region region-content"><section id="block-system-main" class="block block-system clearfix"><div class="view view-mmw-wardead view-id-mmw_wardead view-display-id-page table-responsive view-dom-id-b805411612e258596c1e392c026e81de jquery-once-3-processed">';
           content += '<div class="view-empty">' +Drupal.t("Service currently unavailable. Please try again later.") + '</div>';
           content += '</div></section></div></section>';
          $( ".main-container > .row" ).append( content );
          // Remove loader
          $('#cover-loader').fadeOut();
        });
        
      });
    }

  });
  
  function theme_jsonAPIsearcher (url, autosubmit, lang, language, params, per_page, current_page, results) {
        // Send the data using post
        var posting = $.getJSON( url + '&offset=' + (per_page * current_page) + '&limit=' + per_page);
 
        // Put the results in a div
        posting.done(function( data ) { 
          // Update querystring in URL
          var current_url = window.location.href; 
          var newUrl = current_url;
          if (!autosubmit) {
            $.each( params, function( key, value ) {
              newUrl = theme_updateQueryStringParameter(newUrl, key, value);
            });
            window.history.pushState("object or string", "Title", newUrl);
          }
          
          // Generate pager
          var pager = json_api_search_pager(per_page, current_page, results);
        
          if ($('body').hasClass('front')) {
            $('body').removeClass('front').addClass('not-front').addClass('page-wardead-register');
            // Erase main container.
            $( ".main-container > .row" ).empty();
            // Move search form.
            $(".header-container-wrapper .header-container .content_top").detach().prependTo('.main-container > .row');
            // Hide content bottom.
            $('.content_bottom-wrapper').hide();
          } else {
             $( ".main-container > .row > section" ).remove();
          }

          // Prepare some values to pass to template
          labels = {
            SearchResults: Drupal.t("Search results"),
            Conflict: Drupal.t("Conflict"),
            Surname: Drupal.t("Surname"),
            FirstName: Drupal.t("First name(s)"),
            DateOfDeath: Drupal.t("Date of death"),
            DeathPlace: Drupal.t("Death place"),
            Cemetery: Drupal.t("Cemetery"),
            ResultsNumber: Drupal.t('<strong>@total</strong> Corresponding result', {'@total': results}),
            NoResultsFound: Drupal.t("No results found")
          };
          if (data.length) {
            $.each( data, function( i, item ) {
              var classes = '';
              if(i%2 == 0) classes += 'odd '; else classes += 'even ';
              if(i == 0) classes += 'views-row-first ';
              if(i == data.length-1) classes += 'views-row-last ';
              data[i].classes = classes.replace('/ +$/', '');
              data[i].conflict = item['conflit' + lang];
              if (!isNaN(item['gerepatrieerd']) && item['gerepatrieerd'] != 0) {
                // Show banner.
                if ($('#block-views-banners-b-1list-nav').length && !$('#block-views-banners-b-1list-nav').hasClass('show-it')) {
                  $('#block-views-banners-b-1list-nav').addClass('show-it');
                }
              }
              else {
                // Hide Banner.
                if ($('#block-views-banners-b-1list-nav').length && $('#block-views-banners-b-1list-nav').hasClass('show-it')) {
                  $('#block-views-banners-b-1list-nav').removeClass('show-it');
                }
              }
              data[i].personURL = language + '/' + json_api_settings.dead_person_landing_page_url + '?idPersonne=' + item.idPersonne;
            });
          }
          // Define template //
          var template = require("./templates/search_results.hbs");
          var content = template({data: data, labels: labels, pager: pager});

          $( ".main-container > .row" ).append( content );
          // Remove loader
          $('#cover-loader').fadeOut();
          Drupal.attachBehaviors('#main-content');
        }).fail(function() {
          if ($('body').hasClass('front')) {
            $('body').removeClass('front').addClass('not-front').addClass('page-wardead-register');
            // Erase main container.
            $( ".main-container > .row" ).empty();
            // Move search form.
            $(".header-container-wrapper .header-container .content_top").detach().prependTo('.main-container > .row');
            // Hide content bottom.
            $('.content_bottom-wrapper').hide();
          } else {
             $( ".main-container > .row > section" ).remove();
          }
          var content = '<section class="col-sm-12"><a id="main-content"></a><h1 class="page-header">' + Drupal.t("Search results") + '</h1><div class="region region-content"><section id="block-system-main" class="block block-system clearfix"><div class="view view-mmw-wardead view-id-mmw_wardead view-display-id-page table-responsive view-dom-id-b805411612e258596c1e392c026e81de jquery-once-3-processed">';
           content += '<div class="view-empty">' +Drupal.t("Service currently unavailable. Please try again later.") + '</div>';
           content += '</div></section></div></section>';
          $( ".main-container > .row" ).append( content );
          // Remove loader
          $('#cover-loader').fadeOut();
        });
  }
  
  function json_api_search_pager (per_page, current_page, results) { 
    var pages = Math.ceil(results / per_page);
    var pager = '';
          if (pages > 1) {
            pager += '<div id="pager-json-api-search" class="text-center" results="' + results + '"><ul class="pagination">';
            if (current_page > 0) {
              pager += '<li class="pager-first"><a class="pager-pos-json-api-search" title="Go to first page" href="0">« first</a></li>';
              pager += '<li class="prev"><a class="pager-pos-json-api-search" title="Go to previous page" href="' + (current_page - 1) + '">‹ previous</a></li>';
            }
            for (var i = 0; i < pages; i++) {
              if (i > (5 + current_page) && i < pages - 5) continue;
              if (current_page >= 5 && i <= current_page - 5) continue; 
              //if (current_page >= 5 && i <= current_page - 5) continue; 
              if (current_page == i) {
                pager += '<li class="active"><span>' + (i + 1) + '</span></li>';
              }
              else {
                if (current_page < (pages - 10) && i == (5 + current_page)) {
                  pager += '<li class="pager-ellipsis disabled"><span>…</span></li>';
                }
                else {
                  pager += '<li><a class="pager-pos-json-api-search" title="Go to page ' + (i + 1) + '" href="' + i + '">' + (i + 1) + '</a></li>';
                }
              }
            }
            if (current_page < pages - 1) {
              pager += '<li class="next"><a class="pager-pos-json-api-search" title="Go to next page" href="' + (current_page + 1) + '">next ›</a></li>';
              pager += '<li class="pager-last"><a class="pager-pos-json-api-search" title="Go to last page" href="' + (pages - 1) + '">last »</a></li>';
            }
            pager += '</ul></div>';
          }
      return pager;
  }
  
  // Get querystring from url
  function jsonAPIsearch_getQueryString () {
      var qs = (function(a) {
        if (a == "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i) {
          var p=a[i].split('=', 2);
          if (p.length == 1)
            b[p[0]] = "";
          else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
      })(window.location.search.substr(1).split('&'));
      return qs;
  }
  
  // Update url
  function theme_updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  // Language mapping function from drupal to api shortcut
  function theme_language_mapping(language){
    switch(language) {
      case 'nl':
        return 'Nl';
      case 'fr':
        return 'Fr';
      case 'de':
        return 'Al';
      case 'en':
        return 'Gb';
      default:
        return 'Gb';
    }
  }

  // Escape special characterSet
  function theme_escapeSpecialCharachters(o, str) {
    //return str.replace(/[!"#$%&'()*+,.\/:;<=>?@[\\\]^`{|}~]/g, "\\\\$&");
    str = str.replace(/[%]/g,'');
    str = str.replace(/[#]/g,'%23');
    str = str.replace(/["]/g,'');
    str = str.replace(/[']/g,'');
    str = str.replace(/[\\]/g,'%5C');
    str = str.replace(/[/]/g,'%2F');
    return encodeURI(str);
  }
  
  // Remove special characterSet
  function theme_removeSpecialCharachters(str) {
    return str.replace(/[~'/\@#$%^&_+]/g,'');
  }
  
})(jQuery);
