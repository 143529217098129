// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <img onError='this.src = \"/sites/all/themes/custom/assets/images/WOI-2.png\"' src=\"http://files.warveterans.be/pics/"
    + container.escapeExpression(((helper = (helper = helpers.IdPhoto || (depth0 != null ? depth0.IdPhoto : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"IdPhoto","hash":{},"data":data}) : helper)))
    + ".JPG\" alt=\"photo soldier\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <img src=\"/sites/all/themes/custom/assets/images/WOI-2.png\" alt=\"photo soldier\">\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <div class=\"field field-name-field-mmw-gender\"><div class=\"label-inline\">Geslacht:&nbsp;</div>"
    + container.escapeExpression(((helper = (helper = helpers.geslachtNl || (depth0 != null ? depth0.geslachtNl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"geslachtNl","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <div class=\"field field-name-field-mmw-gender-fr\"><div class=\"label-inline\">Sexe:&nbsp;</div>"
    + container.escapeExpression(((helper = (helper = helpers.geslachtFr || (depth0 != null ? depth0.geslachtFr : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"geslachtFr","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <div class=\"field field-name-field-mmw-gender-al\"><div class=\"label-inline\">Geschlecht:&nbsp;</div>"
    + container.escapeExpression(((helper = (helper = helpers.geslachtAl || (depth0 != null ? depth0.geslachtAl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"geslachtAl","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <div class=\"field field-name-field-mmw-gender-gb\"><div class=\"label-inline\">Sex:&nbsp;</div>"
    + container.escapeExpression(((helper = (helper = helpers.geslachtGb || (depth0 != null ? depth0.geslachtGb : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"geslachtGb","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-nationaliteit\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Nationality : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.nationaliteit || (depth0 != null ? depth0.nationaliteit : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nationaliteit","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "        <div class=\"files-pdf\">\r\n          <a href=\""
    + alias1(((helper = (helper = helpers.pdfUrl || (depth0 != null ? depth0.pdfUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pdfUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"ext\" target=\"_blank\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.CardDepartmentMilitaryCemeteries : stack1), depth0))
    + "<span class=\"ext\"><span class=\"element-invisible\"> (link is external)</span></span></a>\r\n        </div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field \"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Hero : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.gerepatrieerd || (depth0 != null ? depth0.gerepatrieerd : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gerepatrieerd","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-geboortedatum\">\r\n        <div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.DateOfBirth : stack1), depth0))
    + ":&nbsp;</div>\r\n        <span class=\"date-display-single\">"
    + alias1(((helper = (helper = helpers.geboortedatum || (depth0 != null ? depth0.geboortedatum : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"geboortedatum","hash":{},"data":data}) : helper)))
    + "</span>\r\n      </div>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-geboorteplaats\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.PlaceOfBirth : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.geboorteplaats || (depth0 != null ? depth0.geboorteplaats : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"geboorteplaats","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-adres\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Address : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.adres || (depth0 != null ? depth0.adres : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"adres","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-vader\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.NameFather : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.vader || (depth0 != null ? depth0.vader : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"vader","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-moeder\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.NameMother : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.moeder || (depth0 != null ? depth0.moeder : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"moeder","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-echtgenote\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.MarriedWith : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.echtgenote || (depth0 != null ? depth0.echtgenote : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"echtgenote","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-calling\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Profession : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.beroepNl || (depth0 != null ? depth0.beroepNl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"beroepNl","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-calling-fr\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Profession : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.beroepFr || (depth0 != null ? depth0.beroepFr : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"beroepFr","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-calling-al\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Profession : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.beroepAL || (depth0 != null ? depth0.beroepAL : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"beroepAL","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-calling-gb\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Profession : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.beroepGb || (depth0 != null ? depth0.beroepGb : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"beroepGb","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-gestalte\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.BodyHeight : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.gestalte || (depth0 != null ? depth0.gestalte : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gestalte","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-hair-color\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.HairColour : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.haarkleurNl || (depth0 != null ? depth0.haarkleurNl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"haarkleurNl","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-hair-color-fr\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.HairColour : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.haarkleurFr || (depth0 != null ? depth0.haarkleurFr : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"haarkleurFr","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-overledenvermistopdateaaff\">\r\n        <div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.DateOfDeath : stack1), depth0))
    + ":&nbsp;</div><span class=\"date-display-single\">"
    + alias1(((helper = (helper = helpers.overledenVermistOpDateAAfficher || (depth0 != null ? depth0.overledenVermistOpDateAAfficher : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"overledenVermistOpDateAAfficher","hash":{},"data":data}) : helper)))
    + "</span>\r\n      </div>\r\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-death-case\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.CauseOfDeath : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.doodsoorzaakNl || (depth0 != null ? depth0.doodsoorzaakNl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"doodsoorzaakNl","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-death-case-gb\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.CauseOfDeath : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.doodsoorzaakGb || (depth0 != null ? depth0.doodsoorzaakGb : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"doodsoorzaakGb","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-death-case-al\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.CauseOfDeath : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.doodsoorzaakAl || (depth0 != null ? depth0.doodsoorzaakAl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"doodsoorzaakAl","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-death-case-fr\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.CauseOfDeath : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.doodsoorzaakFr || (depth0 != null ? depth0.doodsoorzaakFr : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"doodsoorzaakFr","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-hospitalisatiete\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.PlaatsHospitalisatie : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.hospitalisatieTe || (depth0 != null ? depth0.hospitalisatieTe : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hospitalisatieTe","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-overledenvermistte-\">\r\n        <div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.PlaceOfDeath : stack1), depth0))
    + ":&nbsp;</div><span class=\"lineage-item lineage-item-level-0\">"
    + alias1(((helper = (helper = helpers.overledenVermistTe || (depth0 != null ? depth0.overledenVermistTe : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"overledenVermistTe","hash":{},"data":data}) : helper)))
    + "</span>\r\n      </div>\r\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-plaatsvanherbegravingnl\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.PlaceOfBurial : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.plaatsVanHerbegravingNl || (depth0 != null ? depth0.plaatsVanHerbegravingNl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"plaatsVanHerbegravingNl","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-begraafplaats\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Cemetery : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.begraafplaats || (depth0 != null ? depth0.begraafplaats : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"begraafplaats","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-plaatsvanherbegravingfr\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.PlaceOfBurial : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.plaatsVanHerbegravingFr || (depth0 != null ? depth0.plaatsVanHerbegravingFr : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"plaatsVanHerbegravingFr","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-plaatsvanherbegravinggb\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.PlaceOfBurial : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.plaatsVanHerbegravingGb || (depth0 != null ? depth0.plaatsVanHerbegravingGb : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"plaatsVanHerbegravingGb","hash":{},"data":data}) : helper)))
    + "'</div>\r\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-indiensttreding\">\r\n        <div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.DateOfEnlistment : stack1), depth0))
    + ":&nbsp;</div><span class=\"date-display-single\">"
    + alias1(((helper = (helper = helpers.indiensttreding || (depth0 != null ? depth0.indiensttreding : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"indiensttreding","hash":{},"data":data}) : helper)))
    + "</span>\r\n      </div>\r\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "      <div class=\"field field-name-field-grade\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Rank : stack1), depth0))
    + ":&nbsp;</div><a href=\""
    + alias1(((helper = (helper = helpers.rank_url || (depth0 != null ? depth0.rank_url : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"rank_url","hash":{},"data":data}) : helper)))
    + "\">"
    + alias1(((helper = (helper = helpers.graadHoedanigheidNl || (depth0 != null ? depth0.graadHoedanigheidNl : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"graadHoedanigheidNl","hash":{},"data":data}) : helper)))
    + "</a></div>\r\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "      <div class=\"field field-name-field-grade-fr\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Rank : stack1), depth0))
    + ":&nbsp;</div><a href=\""
    + alias1(((helper = (helper = helpers.rank_url || (depth0 != null ? depth0.rank_url : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"rank_url","hash":{},"data":data}) : helper)))
    + "\">"
    + alias1(((helper = (helper = helpers.graadHoedanigheidFR || (depth0 != null ? depth0.graadHoedanigheidFR : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"graadHoedanigheidFR","hash":{},"data":data}) : helper)))
    + "</a></div>\r\n";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-stamnummer\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.ServiceNumber : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.stamnummer || (depth0 != null ? depth0.stamnummer : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stamnummer","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "      <div class=\"field field-name-field-unit\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Unit : stack1), depth0))
    + ":&nbsp;</div><a href=\""
    + alias1(((helper = (helper = helpers.unit_url || (depth0 != null ? depth0.unit_url : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"unit_url","hash":{},"data":data}) : helper)))
    + "\">"
    + alias1(((helper = (helper = helpers.eenheidNl || (depth0 != null ? depth0.eenheidNl : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"eenheidNl","hash":{},"data":data}) : helper)))
    + "</a></div>\r\n";
},"77":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "      <div class=\"field field-name-field-unit\"><div class=\"label-inline\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.Operations : stack1), depth0))
    + ":&nbsp;</div>"
    + alias1(((helper = (helper = helpers.operation || (depth0 != null ? depth0.operation : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"operation","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<div typeof=\"sioc:Item foaf:Document\" class=\"ds-1col node node-mmw-wardead view-mode-full clearfix\">\r\n  <div class=\"field field-name-field-conflict\">"
    + alias4(((helper = (helper = helpers.conflitNl || (depth0 != null ? depth0.conflitNl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conflitNl","hash":{},"data":data}) : helper)))
    + "</div>\r\n  <div class=\"field field-name-field-conflictfr\">"
    + alias4(((helper = (helper = helpers.conflitFr || (depth0 != null ? depth0.conflitFr : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conflitFr","hash":{},"data":data}) : helper)))
    + "</div>\r\n  <div class=\"field field-name-field-conflictal\">"
    + alias4(((helper = (helper = helpers.conflitAl || (depth0 != null ? depth0.conflitAl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conflitAl","hash":{},"data":data}) : helper)))
    + "</div>\r\n  <div class=\"field field-name-field-conflictgb\">"
    + alias4(((helper = (helper = helpers.conflitGb || (depth0 != null ? depth0.conflitGb : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conflitGb","hash":{},"data":data}) : helper)))
    + "</div>\r\n  <div class=\"group-wie-ben-ik field-group-div\">\r\n    <h3><span>"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.whoami : stack1), depth0))
    + "</span></h3>\r\n    <div class=\"field field-name-photo-soldier\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.IdPhoto : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\r\n    <div class=\"group-nameinfo field-group-div\">\r\n      <div class=\"field field-name-field-voornaamnl\">"
    + alias4(((helper = (helper = helpers.voornaamNl || (depth0 != null ? depth0.voornaamNl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"voornaamNl","hash":{},"data":data}) : helper)))
    + "</div>\r\n      <div class=\"field field-name-field-familienaam\">"
    + alias4(((helper = (helper = helpers.familienaam || (depth0 != null ? depth0.familienaam : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"familienaam","hash":{},"data":data}) : helper)))
    + "</div>\r\n    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.geslachtNl : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.geslachtFr : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.geslachtAl : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.geslachtGb : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nationaliteit : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"field field-name-files-soldier\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pdfUrl : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n    <div class=\"field field-name-field-id-photo\">"
    + alias4(((helper = (helper = helpers.IdPhoto || (depth0 != null ? depth0.IdPhoto : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IdPhoto","hash":{},"data":data}) : helper)))
    + "</div>\r\n    <div class=\"field field-name-field-repertory\">"
    + alias4(((helper = (helper = helpers.repertoire || (depth0 != null ? depth0.repertoire : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"repertoire","hash":{},"data":data}) : helper)))
    + "</div>\r\n    <div class=\"field field-name-field-num-fiche\">"
    + alias4(((helper = (helper = helpers.numFiche || (depth0 != null ? depth0.numFiche : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numFiche","hash":{},"data":data}) : helper)))
    + "</div>\r\n  </div>\r\n  <div class=\"group-leven-oorlog field-group-div\">\r\n    <h3><span>"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.MyLifeBeforeTheWar : stack1), depth0))
    + "</span></h3>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.gerepatrieerd : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.geboortedatum : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.geboorteplaats : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.adres : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.vader : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.moeder : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.echtgenote : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.beroepNl : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.beroepFr : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.beroepAL : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.beroepGb : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.gestalte : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.haarkleurNl : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.haarkleurFr : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\r\n  <div class=\"group-loopbaar field-group-div\">\r\n    <h3><span>"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.MyMilitaryCareer : stack1), depth0))
    + "</span></h3>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.overledenVermistOpDateAAfficher : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.doodsoorzaakNl : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.doodsoorzaakGb : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.doodsoorzaakAl : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.doodsoorzaakFr : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hospitalisatieTe : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.overledenVermistTe : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.plaatsVanHerbegravingNl : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.begraafplaats : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.plaatsVanHerbegravingFr : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.plaatsVanHerbegravingGb : depth0),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.indiensttreding : depth0),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graadHoedanigheidNl : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graadHoedanigheidFR : depth0),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.stamnummer : depth0),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.eenheidNl : depth0),{"name":"if","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.operation : depth0),{"name":"if","hash":{},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\r\n  <div class=\"group-loopbaar field-group-div\">\r\n    <div id=\"disqus_thread\"></div>\r\n  </div>\r\n</div>";
},"useData":true});
